<!--
 * @Description: 
 * @Author: YangXin
 * @Date: 2024-08-07 15:32:12
 * @listEditTime: Do not edit
-->
<template>
    <el-dialog title="编辑模块" append-to-body modal-append-to-body lock-scroll modal @close="closeFunc"
        :visible="eidtDialogType">
        <div class="editMain">
            <div class="menuList">
                <div class="navBox" @click="addItemFunc">
                    {{  }}
                </div>
                <div class="bttomBox">
                    <div v-for="(item, index) in getDataMapByKey(nameType)" :key="index" class="itemBox"
                        :class="{ 'itemBox2': editListMode.id === index }" @click="getItemFunc(item, index)">
                        <div style="width: 70%;padding: 10px 0;">{{ item.title }}</div>
                        <div style="height: 100%;width: 30%;">
                            <img :src="item.src" alt="" style="height: 100%;width: 100%;"/>
                        </div>

                    </div>
                </div>
            </div>
            <div class="editMenu">
                <el-form label-width="80px" :model="editListMode" style="height: 100%;" ref="imgFromList">
                    <el-form-item label="标题">
                        <el-input v-model="editListMode.title"></el-input>
                    </el-form-item>
                    <el-form-item label="封面图片">
                        <el-upload class="avatar-uploader" action="" :show-file-list="false"
                            :on-progress="handleAvatarSuccess">
                            <img v-if="editListMode.imageUrl" :src="editListMode.imageUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item>
                        <el-button>取消</el-button>
                        <el-button @click="savefunc">保存</el-button>

                    </el-form-item>
                </el-form>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import {mapGetters} from "vuex";

import "@wangeditor/editor/dist/css/style.css"
export default {
    components: { Editor, Toolbar },
    props: {
        cardIndex: {
            type: Object,
            default: () => ""
        },
        eidtDialogType: {
            type: Boolean,
            default: () => false
        }
    },
    data() {
        return {
            editListMode: {
                id: 0,
                title: "",
                imageUrl: "",
                html: '',
            },
            cardList: "",
            cardChild: "",
            textItemList: [],
            editor: null,
            toolbarConfig: {},
            editorConfig: { placeholder: '请输入内容...' },
            mode: 'default', // or 'simple'
            nameType:""
        }
    },
    mounted() {
        this.init()

    },

    computed: {
        /**
         * @Author: YangXin
         * @Description: 从store获取元素
         * @Date: 2024-08-07 18:23:53
         * @return {*}
         */
        imgTextList() {
            return this.$store.getters.imgTextList;
        },
        currentDepartment() {
            return this.$store.getters["schema/schema"].value
        },

        ...mapGetters({
            getDataMapByKey: 'schema/dataMapByKey',    // 将 store.getters.count 映射到 this.count
        })

    },




    methods: {
        /**
         * @Author: YangXin
         * @Description: 初始化获取修改的数组
         * @Date: 2024-08-07 18:18:03
         * @return {*}
         */
        init: function () {
            if (this.cardIndex) {
                this.cardList = this.currentDepartment[this.cardIndex.dataCardIndex],
                    this.cardChild = this.cardList.children[this.cardIndex.cardTwoIndex]
            } else {
                this.cardList = this.currentDepartment[this.$parent.formListMode.dataCardIndex],
                    this.cardChild = this.cardList.children[this.$parent.formListMode.cardChildIndex]
            }
            if (this.cardChild.children) {
                this.nameType = this.cardChild.children[0].dataSources.name
            } else {
                this.textItemList = JSON.parse(JSON.stringify(this.imgTextList.dataSources))

            }

            // 初始化选择第一个
            if(this.getDataMapByKey(this.nameType)){
                let item = this.getDataMapByKey(this.nameType)[this.editListMode.id]
                this.editListMode.title = item.title
                this.editListMode.imageUrl = item.src
            }

        },

        /**
         * @Author: YangXin
         * @Description: 添加文章
         * @Date: 2024-08-07 18:21:32
         * @return {*}
         */
        addItemFunc: function () {
            this.getDataMapByKey(this.nameType).unshift({
                "title": "",
                "imageContent": "",
                "releaseTime": "",
                isSave: false,
                "deptName": ""
            })
        },

        /**
         * @Author: YangXin
         * @Description: 上传图片回显
         * @Date: 2024-08-07 18:18:07
         * @param {*} res
         * @param {*} file
         * @return {*}
         */
        handleAvatarSuccess(res, file) {
            this.editListMode.imageUrl = URL.createObjectURL(file.raw);
        },
        /**
         * @Author: YangXin
         * @Description: 
         * @Date: 2024-08-07 18:18:12
         * @param {*} editor
         * @return {*}
         */
        onCreated(editor) {
            this.editor = Object.seal(editor)
        },
        /**
         * @Author: YangXin
         * @Description: 点击获取修改的item
         * @Date: 2024-08-08 09:07:28
         * @param {*} item
         * @return {*}
         */
        getItemFunc: function (item, index) {
            // {
            //         "title": "12312",
            //         "imageContent": require("@/assets/images/data/mls.png"),
            //         "releaseTime": "2024-08-07 11:10:37",
            //         isSave:false,
            //         "deptName": "yx"
            // }
            this.editListMode.id = index

            this.editListMode.title = item.title
            this.editListMode.imageUrl = item.src


        },

        /**
         * @Author: YangXin
         * @Description: 
         * @Date: 2024-08-08 10:17:20
         * @return {*}
         */
        savefunc: function () {
   
            let item = this.getDataMapByKey(this.nameType)[this.editListMode.id]

            item.title = this.editListMode.title
            item.src = this.editListMode.imageUrl
            item.isSave = true
        },

        /**
         * @Author: YangXin
         * @Description: 
         * @Date: 2024-08-08 10:41:22
         * @return {*}
         */
        closeFunc: function () {
            // console.log(this.cardChild.children)
            // this.imgTextList.data = this.textItemList
            // this.cardChild.children = [JSON.parse(JSON.stringify(this.imgTextList))]
            this.$parent.imgTextEditType = false
        }

    },



}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
    width: 80% !important;
    border-radius: 10px;
background: #F0F0F0;


}
// background: #F0F0F0;
// border-radius: 10px;
/deep/ .avatar-uploader .el-upload {
    border: 1px dashed #9c2b2b !important;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

/deep/ .avatar-uploader .el-upload:hover {
    border-color: #409EFF !important;
}

/deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.editMain {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;

    .menuList {
        width: 30%;
        padding-top: 10px;
        padding: 10px;
        background: #ffffff;
        // border: 1px solid black;
        height: 725px;

        .navBox {
            cursor: pointer;
            height: 60px;
            width: 100%;
            text-align: center;
            line-height: 60px;
            font-size: 16px;
            font-family: Arial, Helvetica, sans-serif;
            border-radius: 8px;
            color: #0074AB;
            border: 1px solid #0074AB;
        }

        .navBox:hover,
        .itemBox:hover {
            background: #F0F0F0;
        }

        .bttomBox {
            padding-top: 10px;
            height: calc(100% - 60px);
            overflow-y: scroll !important;
            /* 或者 overflow-x: scroll; 根据需要选择 */
        }

        .itemBox {
            width: 100%;
            cursor: pointer;
            height: 80px;
            padding: 5px 8px;
            border-radius: 4px;
            display: flex;
        }

        .itemBox2 {
            background: #F0F0F0;

            // box-shadow: 0px 18px 15px -3px rgba(0, 0, 0, 0.1);
        }
    }

    .editMenu {
        padding-top: 10px;
        margin-left:10px ;
        background: #ffffff;
        height: 100%;
        width: 69%;




        .avatar {
            width: 178px;
            height: 178px;
            display: block;
        }
    }
}
</style>